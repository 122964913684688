<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
    <g clip-path="url(#clip0_7467_28227)">
      <path
          d="M10 6.58333V10.0833C10 10.3928 9.87708 10.6895 9.65829 10.9083C9.4395 11.1271 9.14275 11.25 8.83333 11.25H2.41667C2.10725 11.25 1.8105 11.1271 1.59171 10.9083C1.37292 10.6895 1.25 10.3928 1.25 10.0833V3.66667C1.25 3.35725 1.37292 3.0605 1.59171 2.84171C1.8105 2.62292 2.10725 2.5 2.41667 2.5H5.91667"
          :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.25 0.75H11.75V4.25" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.33594 7.16667L11.7526 0.75" :stroke="stroke" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_7467_28227">
        <rect width="12" height="12" :fill="fill" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ExternalLinkIcon',
  props: {
    stroke: {
      type: String,
      default: 'black'
    },
    fill: {
      type: String,
      default: 'black'
    }
  }
}
</script>
